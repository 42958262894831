<template>
  <div>
    <div class="content">
      <div class="container">
        <div class="courses-title">
          <el-page-header
            :title="$t('courses.back')"
            @back="historyBack"
            :content="courses[0]['name']"
          ></el-page-header>
        </div>
        <div class="courses-list">
          <el-collapse class="course-playlist-detail">
            <el-collapse-item
              class="play"
              v-for="course in courses"
              :key="course.course_id"
            >
              <template slot="title" style="height:70px">
                <el-progress
                  type="circle"
                  :width="60"
                  style="margin-right:20px"
                  :percentage="progress(course.completed, course.incompleted)"
                ></el-progress>
                <h3 v-html="course.course_name"></h3>
              </template>
              <div class="progress">
                <el-progress
                  :stroke-width="8"
                  :percentage="progress(course.completed, course.incompleted)"
                ></el-progress>
              </div>
              <div
                class="playChild"
                :class="{ playChildActive: child.active }"
                v-for="(child, child_index) in course.children"
                :key="child.following_id"
              >
                <div class="childContent">
                  <el-card
                    shadow="hover"
                    :class="setBorder(child.is_completed)"
                  >
                    <h4>{{ child_index + 1 }}. {{ child.item_name }}</h4>
                    <div v-if="child.is_completed == 1">
                      <span
                        ><i class="el-icon-date"></i> {{ child.end_time }}</span
                      >
                    </div>
                    <div v-if="child.is_completed == 1">
                      <span
                        ><i class="el-icon-timer"></i>
                        {{ child.used_times }}'</span
                      >
                    </div>
                    <div>
                      <span
                        v-if="child.is_completed == 1"
                        style="color: #42a16a"
                        ><i class="el-icon-success"></i>
                        {{ $t("courses.completed") }}
                      </span>
                      <span
                        v-if="child.is_completed == 0"
                        style="color: #F56C6C"
                        ><i class="el-icon-error"></i>
                        {{ $t("courses.incompleted") }}
                      </span>
                      <span
                        v-if="child.is_completed == 2"
                        style="color: #E6A23C"
                        ><i class="el-icon-warning"></i> 进行中</span
                      >
                    </div>
                  </el-card>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";

export default {
  name: "BackendData",
  components: {},
  mixins: [],
  // props: [],
  data() {
    return {
      courses: []
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"]),
    student_id() {
      return this.$route.query.student_id;
    },
    course_id() {
      return this.$route.query.course_id;
    }
  },
  watch: {},
  mounted() {
    console.log(this.course_id);
    if (this.course_id == undefined) {
      this.getStudentCourse();
    } else {
      this.studySchedule();
    }
  },
  methods: {
    setBorder(index) {
      let color = "";
      if (index == 0) {
        color = "danger-border";
      }
      if (index == 1) {
        color = "success-border";
      }
      if (index == 2) {
        color = "warning-border";
      }
      return color;
    },
    progress(completed, incompleted) {
      let total = completed + incompleted;
      return Math.round((completed / total) * 100);
    },
    async getStudentCourse() {
      const data = await onlineCourseApi.studentCourse({
        lang: this.langValue,
        student_id: this.student_id
      });
      this.courses = data;
    },
    async studySchedule() {
      const data = await onlineCourseApi.studySchedule({
        lang: this.langValue,
        student_id: this.student_id,
        course_id: this.course_id
      });
      this.courses = data;
    }
  }
};
</script>

<style scoped>
.courses-title {
  margin: 20px 0;
}
.success-border >>> .el-card__body {
  border: 1px solid #42a16a;
}

.danger-border >>> .el-card__body {
  border: 1px solid #f56c6c;
}

.warning-border >>> .el-card__body {
  border: 1px solid #e6a23c;
}
.courses-list >>> .el-collapse-item__header {
  height: 70px;
  line-height: 70px;
}

.courses-list {
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dcdfe6;
}

.childContent {
  margin: 10px 0;
}
</style>
